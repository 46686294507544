import * as React from "react";
import Layout from "../../components/Layout";
import GeneralTab from "../../components/HTabs";
import { Seo } from "../../components/seo";
import Service from "../../components/Services";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import Document from "../../components/Document";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function IndianSubsidiaryCompany() {
  // banner Slider
  // const IndianSlider = [

  //   {
  //     header_color: true,
  //     content_color: true,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83`,
  //     header: `Indian Subsidiary Company Registration`,
  //     content: `Best Indian Subsidiary Company Registration Platform`,
  //     image: "/imgs/business/sliders/subsidiary-slider.png",
  //     alt_tag: "Best Online Indian Subsidiary Registration in Hosur",
  //   },

  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Indian Subsidiary Company Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `6999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* About Data Without Form*/
  const IscAboutData = {
    title: `Indian Subsidiary Company`,
    sub_title: `Indian Subsidiary Company Registration in just 7 Days`,
    paragraph: `According to the Companies Act of 2013, an Indian subsidiary
  company is one in which a foreign corporate entity or parent
  entity owns at least 50% of the total share capital. A
  subsidiary company can be controlled or monitored by the
  parent firm, and it must follow Indian subsidiary company
  regulations. Online registration is required in order to
  comply with Indian government laws.`,
    header: `Our Features in Indian Subsidiary Company in India`,
    points: [
      `Incorporated Association`,
      `Legal Entity`,
      `Limited Liability`,
      `Transferability of Shares`,
    ],
  };
  /* Service Data */
  const IndianSubsidy = {
    header: `Advantages of Indian Subsidiary Company Registration`,
    content: ` According to the Companies Act of 2013, an Indian subsidiary
    company is one in which a foreign corporate entity or parent
    entity owns at least 50% of the total share capital.`,
    image: '/imgs/business/subsidiary.png',
    alt_img: 'TODAYFILINGS Indian Subsidiary Company Registration',
    points: [
      `Perpetual Succession`,
      `Direct Foreign Investment`,
      `Scope Of Expansion`,
      `Obtain Property In India`,
      `Get Loan`,
      `Right to Sue and Sued`,
    ],
  };
  /* FaQ */

  var IndianSubsidyFAQ = [
    {
      header: 'What is DSC?',
      body: [
        {
          content: `You can sign electronic documents using the DSC, which is simply a
          Digital Signature Certificate issued by certifying authority (TCS
          and n-Code). Since a DSC is required for every document when forming
          a private limited company in India.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'How to set up Indian Subsidiary Company?',
      body: [
        {
          content: `Indian Subsidiary Company must enrol with equitable treatment and
          then disclose all of its records. Similar steps are taken as with
          the Indian Private Limited Company.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Do we need a resident Indian to be a director in an Indian company?',
      body: [
        {
          content: `Every company to be registered in India must have at least one Indian resident individual as a director.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can Indian Company be 100% Subsidiary of a Parent Company?',
      body: [
        {
          content: `Yes as the Indian Company requires at least 2 investors and thusly
          can be 100% subsidiary of the Parent Company.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What is the control over percentage in an Indian Subsidiary Entity?',
      body: [
        {
          content: `As per the provisions of the Companies Act 2013, the control over 
          percentage for an Indian Subsidiary Company is 50%.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Can an NRI or Foreigner have control over an Indian Subsidiary Company?',
      body: [
        {
          content: `Yes, an NRI or a Foreigner can have control over an Indian Subsidiary Company.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What are the minimum requirements to start an Indian Subsidiary Company?',
      body: [
        {
          content: `The minimum requirements to start an Indian Subsidiary Company are, 
          a minimum of 2 shareholders and Directors, DIN, and DSC.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Is it compulsory to appoint a Foreign Director in a subsidiary company?',
      body: [
        {
          content: `Yes, it is compulsory to appoint a Foreign Director in a subsidiary company.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: 'Do I need to be present during the registration process?',
      body: [
        {
          content: `The registration of an Indian subsidiary company is done entirely
          online. Nobody should actually be available to help with corporate
          concerns at our workplace. For a record signature, we will send our
          representative to your house or workplace.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: 'How to set up a Subsidiary Company in India?',
      body: [
        {
          content: `A subsidiary company can be established in India by enrolling in a
          fair registration process and submitting all required documentation
          afterward. The procedure for registering an Indian subsidiary
          company is very similar to that of a private limited business in
          India.`,
          icon: false,
        },
      ],
    },
  ];

  /* Document Section Data */
  const IscDocData = {
    id: 'ics-doc',
    heading: `Indian Subsidiary Company Registration`,
    sub_heading: `Documents needed for Indian Subsidiary Company Registration`,
    doc_contents: [
      {
        col: `col-lg-6 col-md-6`,
        delay: '100',
        img: `/imgs/icons/bregistration/application.png`,
        alt_tag: `DIN & DCS`,
        header: `DIN & DCS Application`,
        description: `Obtaining Digital Signature Certificate (DSC) and Director
          Identification Number (DIN) for the company's directors.`,
      },
      {
        col: `col-lg-6 col-md-6`,
        delay: '200',
        img: `/imgs/icons/bregistration/pancard.png`,
        alt_tag: `Identity Proof`,
        header: `Identity Proof`,
        description: ` PAN and TAN serve two different purposes. Therefore, it is
          mandatory for those deducting tax at source to obtain a TAN.`,
      },
      {
        col: `col-lg-6 col-md-6`,
        delay: '300',
        img: `/imgs/icons/bregistration/banking.png`,
        alt_tag: `Bank Account for Company`,
        header: `Bank account for company`,
        description: `The MoA, AoA, Certificate of Incorporation, and PAN of the
          firm must be shown to the bank in order to open a bank
          account.`,
      },
      {
        col: `col-lg-6 col-md-6`,
        delay: '400',
        img: `/imgs/icons/bregistration/allotment.png`,
        alt_tag: `GST-IN Allotment`,
        header: `GST-IN Allotment`,
        description: `GST-IN consists of a 15 digit identification number. The
          allocation of the number to the taxpayer was based on PAN
          and State of the applicant.`,
      },
    ],
  };

  /* Tab Data */
  const IscTabsData = {
    id: 'features',
    heading: 'Registration procedure of Indian Subsidiary Company',
    paragraph: `A new straightforward form has been made available by the
    Ministry of Corporate Affairs to make the registration process
    for Indian Subsidiary Companies simple. The form is called the
    SPICe+ form and has two sections:`,
    body: [
      {
        tab_title: 'ACT',
        tab_content: [
          {
            content_title: '',
            content_paragraph: '',
            points: [
              `Compliance with Income Tax Act, 1961`,
              `Compliance with Companies Act, 2013`,
              `Guidelines with MCA, Ministry of Corporate Affairs`,
              `FEMA Guidelines`,
              `Annual Return with the Registrar of Company (ROC)`,
              `Income Tax Return`,
              `Filling with the RBI, Reserve Bank of India`,
              `Filling with the SEBI, Securities and Exchange Board of India`,
            ],
          },
        ],
        content_img: '/imgs/business/din.png',
        alt_img: 'Best Indian Subsidiary Registration Services In Hosur',
      },

      {
        tab_title: 'Section A',
        tab_content: [
          {
            content_title: 'Section (A) Has Name Reservation Process',
            content_paragraph: `Once the name reservation process is complete, all
            incorporation applications, including:`,
            points: [
              `DIN Application`,
              `Issuance of PAN and TAN`,
              `Opening a Bank Account for the company`,
              `Allotment of GST-IN`,
            ],
          },
          {
            content_title: '',
            content_paragraph: `Without receiving a Digital Signature Certificate, or DSC,
            from the Relevant Authority, the registration process for
            an Indian Subsidiary Company is not complete. DSC is a
            necessary requirement for any kind of business.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/reservation.jpg',
        alt_img:
          'TODAYFILINGS Indian Subsidiary Registration Services In Hosur',
      },
      {
        tab_title: 'Section B',
        tab_content: [
          {
            content_title: '',
            content_paragraph: `When the name reservation process is finished, Section B
            process begins. This part of the process consists of all
            the incorporation steps, and they are:`,
            points: [],
          },
          {
            content_title: 'Capital',
            content_paragraph: `The registration procedure does not require a minimum capital.`,
            points: [],
          },
          {
            content_title: 'Directors',
            content_paragraph: `Minimum of two directors are required for Indian
            subsidiary company registration, and it's crucial that at
            least one of them is an Indian resident.`,
            points: [],
          },
        ],
        content_img: '/imgs/registration/shop/shop-imgslider.png',
        alt_img: 'Online Indian Subsidiary Registration Services In Hosur',
      },
      {
        tab_title: 'Shares',
        tab_content: [
          {
            content_title: 'Shareholders',
            content_paragraph: `The Indian Subsidiary Company must have a minimum of two
            shareholders in order to register.`,
            points: [],
          },
          {
            content_title: 'Equity Shares',
            content_paragraph: `Parent companies must own about 50% of the equity share capital.`,
            points: [],
          },
          {
            content_title: 'DIN',
            content_paragraph: `All Directors must have a DIN, or Director Identification Number.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/partnership-agreement.png',
        alt_img:
          'TODAYFILINGS Indian Subsidiary Registration Services Near Bangalore',
      },
    ],
  };

  return (
    <div>
      <Seo
        title='Indian Subsidiary Company'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={IndianSlider} /> */}

          <Package packageData={PackageCompData} />

          <About about_data={IscAboutData} />

          <Counter />

          <Service item={IndianSubsidy} />

          <Document docSecData={IscDocData} />

          <GeneralTab HTabsData={IscTabsData} />
          <Cta />
          <FAQAccord items={IndianSubsidyFAQ} />
        </main>
      </Layout>
    </div>
  );
}
